.img {
  width: 10px;
  object-fit: contain;
}

.projectImg {
  width: 100%;
  object-fit: contain;
}

.projSubHead {
  font-weight: 600;
}

.projBtns {
  padding: 7.5px;
  font-size: 1rem;
}
