/* Aligh navbar right */
.navbar-nav {
  text-align: right;
}
.navbar {
  text-align: right;
  background-color: #006075dd;
  transition: 0.5s;
}

.navbar:hover {
  background-color: #006075;
}

.nav-link {
  color: white;
}

@media screen and (max-width: 373px) {
  #navLogo {
    width: 180px;
  }
}
@media screen and (max-width: 350px) {
  #navLogo {
    width: 130px;
  }
}
