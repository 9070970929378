/* Setting text-align and background fish image with transparent backgrund */
.HomeAboveFold {
  text-align: center;
  background-image: url("./../../public/images/fish-transparent.png");
  background-position: right;
  background-repeat: no-repeat;
}

/* Image of myself. */
#profileImg {
  border-radius: 50%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  width: 100%;
  height: 100%;
  max-height: 225px;
  max-width: 225px;
  object-fit: cover;
  overflow: hidden;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* Name text formatting */
#name {
  font-size: 25px;
  font-family: Nunito;
  font-weight: 600;
}

/* contact icon's text labels font size */
.contact-label {
  font-weight: 600;
}

/* Contact icon column hover effect */
.contact-col {
  margin-left: 1rem;
  transition: 0.5s;
}
.contact-col:hover {
  transform: scale(1.1);
}

/* link to contact item */
.contact-col-link {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: contents;
}

/* contact icom img formatting */
.icon-contact-img {
  max-width: 100px;
  max-height: 100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
