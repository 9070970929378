.Footer {
  background-color: #346e7b;
}

#copyright {
  border-top: 1px solid white;
}

icon-footer-img {
  height: 15px;
  width: 15px;
  margin-left: auto;
  margin-right: auto;
}

#ftrIconCol {
  padding-left: 50px;
}
