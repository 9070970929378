/* FONT IMPORTS: Roboto and Nunito */
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap");

/* CUSTOM CSS  */

/* Main background color and default font alignment */
.App {
  text-align: center;
  background-color: #184c57;
}

/* Default font color for all text */
h1,
h2,
h3,
h4,
p,
button,
label {
  color: rgb(255, 255, 255);
}

/* Heading font */
h1,
h2,
h3,
h4 {
  font-family: Roboto;
}

/* Body font */
p,
button,
label {
  font-family: Nunito;
}

/* Default button style */
button {
  background-color: rgba(255, 255, 255, 0.3);
  padding: 15px;
  border: 0.5px solid #ffffff;
  border-radius: 5px;
  font-weight: 600;
  height: auto;
  width: auto;
}

/* Alternative button style */
.btn-outline-light {
  background-color: rgba(255, 255, 255, 0.3);
  font-size: 22px;
  font-family: Nunito;
  font-weight: 600;
  display: inline-block;
  width: fit-content;
  transition: 0.5s;
}

/* Alternative button style on hover */
.btn-outline-light:hover {
  color: #184c57;
}

/* Custom font style for H1 */
.head1 {
  font-size: 35px;
  font-family: Roboto;
  color: #ffffff;
  font-weight: 600;
}

/* Custom font style for H2 */
.head2 {
  font-size: 23px;
  font-family: Nunito;
  color: #c8e6ec;
  font-weight: 600;
}

/* Custom font style for H3 */
.head3 {
  font-family: Roboto;
  color: #ffffff;
}

/* Custom CSS for History section sticky headers */
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 1020;
  }
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top2 {
    position: -webkit-sticky;
    position: sticky;
    top: 68px;
    z-index: 1021;
  }
}
